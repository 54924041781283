<template>
  <section>
    <b-row>
      <b-col cols="12">
        <payouts-list />
      </b-col>
    </b-row>
  </section>
</template>

<script>
import {
  BRow, BCol,
} from 'bootstrap-vue'
import PayoutsList from '@/views/PayoutsList.vue'

export default {
  components: {
    BRow,
    BCol,

    PayoutsList,
  },
}
</script>

<style>

</style>
