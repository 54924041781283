<template>
  <div>
    <b-overlay :show="show" rounded="sm" variant="dark">
      <div>
        <b-card>
          <b-row>
            <b-col md="12" sm="12">
              <b-button
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                v-b-modal.modal-payout
                variant="outline-primary"
                class="float-right"
                v-if="isApproved"
              >
                <feather-icon
                  icon="ActivityIcon"
                  size="16"
                  class="bg-light-danger"
                />
                <span class="align-middle ml-50">Initiate Payout</span>
              </b-button>
            </b-col>
          </b-row>
          <b-row>
            <b-col md="2" sm="4" class="my-1">
              <b-form-group class="mb-0">
                <label class="d-inline-block text-sm-left mr-50"
                  >Per page</label
                >
                <b-form-select
                  id="perPageSelect"
                  v-model="perPage"
                  size="sm"
                  :options="pageOptions"
                  class="w-50"
                />
              </b-form-group>
            </b-col>
            <b-col md="4" sm="8" class="my-1">
              <b-form-group
                label="Sort"
                label-cols-sm="3"
                label-align-sm="right"
                label-size="sm"
                label-for="sortBySelect"
                class="mb-0"
              >
                <b-input-group size="sm">
                  <b-form-select
                    id="sortBySelect"
                    v-model="sortBy"
                    :options="sortOptions"
                    class="w-75"
                  >
                    <template v-slot:first>
                      <option value="">-- none --</option>
                    </template>
                  </b-form-select>
                  <b-form-select
                    v-model="sortDesc"
                    size="sm"
                    :disabled="!sortBy"
                    class="w-25"
                  >
                    <option :value="false">Asc</option>
                    <option :value="true">Desc</option>
                  </b-form-select>
                </b-input-group>
              </b-form-group>
            </b-col>
            <b-col md="6" class="my-1">
              <b-form-group
                label="Filter"
                label-cols-sm="3"
                label-align-sm="right"
                label-size="sm"
                label-for="filterInput"
                class="mb-0"
              >
                <b-input-group size="sm">
                  <b-form-input
                    id="filterInput"
                    v-model="filter"
                    type="search"
                    placeholder="Type to Search"
                  />
                  <b-input-group-append>
                    <b-button :disabled="!filter" @click="filter = ''">
                      Clear
                    </b-button>
                  </b-input-group-append>
                </b-input-group>
              </b-form-group>
            </b-col>

            <b-col cols="12">
              <b-table
                striped
                hover
                responsive
                :per-page="perPage"
                :current-page="currentPage"
                :items="items"
                :fields="fields"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
                :sort-direction="sortDirection"
                :filter="filter"
                :filter-included-fields="filterOn"
                @filtered="onFiltered"
              >
                <template #cell(amount)="data">
                  {{
                    "₦" +
                    Number(data.item.amount).toLocaleString(undefined, {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })
                  }}
                </template>

                <template #cell(recipient)="data">
                  <p class="mb-0 text-nowrap">
                    <small>
                      {{ "Name: " + data.item.bank_account.account_name }}<br />
                      {{ "Account: " + data.item.bank_account.account_number
                      }}<br />
                      {{ "Bank: " + data.item.bank_account.bank_name }}<br />
                      {{ "Code: " + data.item.recipient_code }}<br />
                    </small>
                  </p>
                </template>

                <template #cell(status)="data">
                  <b-badge :variant="status[1][data.value]">
                    {{ status[0][data.value] }}
                  </b-badge>
                  <div class="text-nowrap mt-1">
                    <feather-icon
                      v-show="data.item.status != 'SUCCESS'"
                      :id="`invoice-row-${data.item.id}-verify-icon`"
                      icon="RepeatIcon"
                      class="cursor-pointer"
                      size="16"
                      @click="verifyPayout(data.item.reference)"
                    />
                    <b-tooltip
                      title="Verify Payout"
                      class="cursor-pointer"
                      :target="`invoice-row-${data.item.id}-verify-icon`"
                    />
                  </div>
                </template>

                <template #cell(wallet)="data">
                  <p class="mb-0 text-nowrap">
                    <small>{{
                      "Before: ₦" +
                      Number(data.item.wallet_before).toLocaleString()
                    }}</small>
                  </p>
                  <p class="mb-0 text-nowrap">
                    <small>{{
                      "After: ₦" +
                      Number(data.item.wallet_after).toLocaleString()
                    }}</small>
                  </p>
                </template>

                <!-- date -->
                <template #cell(created_at)="data">
                  <div class="d-flex flex-column text-nowrap">
                    <span class="mb-25">{{
                      new Date(data.item.created_at).toLocaleTimeString(
                        "en-us",
                        {
                          weekday: "short",
                          year: "numeric",
                          month: "short",
                          day: "numeric",
                        }
                      )
                    }}</span>
                  </div>
                </template>

                <!-- <template #cell(actions)="data">
            <div class="text-nowrap">
              <feather-icon
                v-show="data.item.status != 'SUCCESS'"
                :id="`invoice-row-${data.item.id}-verify-icon`"
                icon="RepeatIcon"
                class="cursor-pointer"
                size="16"
                @click="verifyPayout(data.item.reference)"
              />
              <b-tooltip
                title="Verify Payout"
                class="cursor-pointer"
                :target="`invoice-row-${data.item.id}-verify-icon`"
              />
            </div>
          </template> -->
              </b-table>
            </b-col>

            <b-col cols="12">
              <b-pagination
                v-model="currentPage"
                :total-rows="totalRows"
                :per-page="perPage"
                align="center"
                size="sm"
                class="my-0"
              />
            </b-col>
          </b-row>
        </b-card>
      </div>
    </b-overlay>
  </div>
</template>

<script>
import {
  BCard,
  BTable,
  BAvatar,
  BBadge,
  BRow,
  BCol,
  BFormGroup,
  BFormSelect,
  BPagination,
  BInputGroup,
  BFormInput,
  BInputGroupAppend,
  BButton,
  BTooltip,
  BOverlay,
  VBModal,
} from "bootstrap-vue";

import Ripple from "vue-ripple-directive";
import { avatarText } from "@core/utils/filter";
import { apiUrl } from "@/constants/config";
import store from "@/store";
import axios from "axios";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

import { mapGetters } from "vuex";

export default {
  directives: {
    "b-modal": VBModal,
    Ripple,
  },
  components: {
    BCard,
    BTable,
    BAvatar,
    BBadge,
    BRow,
    BCol,
    BFormGroup,
    BFormSelect,
    BPagination,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
    BTooltip,
    BOverlay,
  },
  data() {
    return {
      perPage: 5,
      pageOptions: [5, 10, 25],
      totalRows: 1,
      currentPage: 1,
      sortBy: "",
      sortDesc: false,
      sortDirection: "asc",
      filter: null,
      filterOn: [],
      infoModal: {
        id: "info-modal",
        title: "",
        content: "",
      },
      fields: [
        /* {
          key: "id",
          label: "Id",
        }, */
        "reference",
        {
          key: "amount",
          label: "Amount",
        },
        { key: "recipient", label: "recipient", sortable: true },
        { key: "status", label: "Status", sortable: true },
        "transfer_code",
        {
          key: "wallet",
          label: "Wallet",
        },
        { key: "created_at", label: "Date", sortable: true },
        //{ key: "actions", label: "Actions" },
      ],
      items: [],
      status: [
        {
          SUCCESS: "SUCCESS",
          QUEUED: "QUEUED",
        },
        {
          SUCCESS: "light-success",
          QUEUED: "light-primary",
        },
      ],
      show: false,
    };
  },
  setup() {
    return {
      avatarText,
    };
  },
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter((f) => f.sortable)
        .map((f) => ({ text: f.label, value: f.key }));
    },
    ...mapGetters(["payoutAdded"]),
    isApproved(){
      return store.state.user.currentUser.user.kyc_approved == 1;
    }
  },
  watch: {
    payoutAdded(val) {
      if (val && val == true) {
        this.getPayouts();
      }
    },
  },
  mounted() {
    // Set the initial number of items
    this.totalRows = this.items.length;
  },
  created() {
    this.getPayouts();
  },
  methods: {
    info(item, index, button) {
      this.infoModal.title = `Row index: ${index}`;
      this.infoModal.content = JSON.stringify(item, null, 2);
      this.$root.$emit("bv::show::modal", this.infoModal.id, button);
    },
    resetInfoModal() {
      this.infoModal.title = "";
      this.infoModal.content = "";
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    getPayouts() {
      this.show = true;
      try {
        axios
          .get(apiUrl + "payout", {
            headers: {
              Authorization: "Bearer " + store.state.user.currentUser.token,
            },
          })
          .then((response) => {
            this.items = response.data.data;
            store.state.user.payoutAdded = false;
          })
          .catch((error) => {
            console.log(error);
          })
          .finally(() => {
            this.show = false;
          });
      } catch (e) {
        console.log(e);
      }
    },
    verifyPayout(reference) {
      try {
        axios
          .get(apiUrl + "payout/verify/" + reference, {
            headers: {
              Authorization: "Bearer " + store.state.user.currentUser.token,
            },
          })
          .then((response) => {
            store.state.user.payoutAdded = true;
            //console.log(response.data.data);
            //this.items = response.data.data;
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Success: " + response.data.message + "!",
                icon: "EditIcon",
                variant: "success",
              },
            });
          })
          .catch((error) => {
            console.log(error);
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Error: " + error.message,
                icon: "EditIcon",
                variant: "danger",
              },
            });
          });
      } catch (e) {
        console.log(e);
      }
    },
  },
};
</script>
